import axios from 'axios';
import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { MAIN_HOST } from 'common/AppConstant';
import { VERSION_URL_FOR_SEARCH_RATE, VERSION_URL } from 'common/Constants';
import { decreaseFetchingQueue } from 'app/state/reducer';

export const search = createAsyncThunk('search', async (data) => {
  const response = await axios.post(`${MAIN_HOST}/search/state`, data);

  return response.data;
});

export const searchWithFilter = createAsyncThunk(
  'searchWithFilter',
  async (data) => {
    const response = await axios.post(`${MAIN_HOST}/search/state`, data);

    return response.data;
  },
);

export const newSearchWithFilter = createAsyncThunk(
  'newSearchWithFilter',
  async (data) => {
    const response = await axios.post(`${MAIN_HOST}/search/state`, data);

    return response.data;
  },
);

export const searchToDownload = createAsyncThunk(
  'saveRequiredFile',
  async (data) => {
    const response = await axios.post(`${MAIN_HOST}/required-file`, data);
    return response.data;
  },
);

export const searchNetworks = createAsyncThunk('searchNetworks', async (arg, { getState }) => {
  const { panoramaVersion } = getState().app || { panoramaVersion: 1 };
  const response = await axios.get(`${MAIN_HOST}/lookup/${VERSION_URL[panoramaVersion]}states`);
  return response.data;
});

// not a thunk
export const callSuggestionApi = async (data) => {
  const response = await axios.post(`${MAIN_HOST}/search/suggestion`, data);
  return response.data;
};

export const getSuggestion = createAsyncThunk(
  'search/suggestion',
  callSuggestionApi,
);

export const onKeywordChange = createAction('search/keywordChange');
export const clearSuggestions = createAction('search/clearSuggestion');
export const clearStatus = createAction('search/clearStatus');

export const getMrfRatesResult = async (
  request,
  setCount,
  setCountVisibility,
  resolve,
  panoramaVersion,
  store,
) => {
  axios
    .post(`${MAIN_HOST}/search${VERSION_URL_FOR_SEARCH_RATE[panoramaVersion]}`, request)
    .then((m) => {
      const { data } = m;
      setCount(data?.total >= 10000 ? 10000 : data?.total);
      setCountVisibility(true);
      resolve({
        data: data.rates?.content,
        page: data.rates?.number,
        size: data.rates?.size,
        totalCount: data?.total,
      });
    })
    .catch(() => {
      resolve({
        data: [],
        page: 0,
        totalCount: 0,
      });
      setCount(0);
      setCountVisibility(true);
      store.dispatch(decreaseFetchingQueue());
    });
};

export default search;
