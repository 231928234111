import axios from 'axios';
import store from 'app/store';
import { decreaseFetchingQueue } from 'app/state/reducer';
import { MAIN_HOST } from './AppConstant';
import { DIRECTION_DESC, TABULAR_VIEWS } from './Constants';

// reusable function to get data for views built on material table
export default function getDataForTabularViews(viewName, columns, query, data = {}) {
  return new Promise((resolve) => {
    const view = TABULAR_VIEWS(data)[viewName];
    const result = {
      data: [],
      page: 0,
      totalCount: 0,
    };

    const request = {
      page: query.page,
      size: query.pageSize,
      searchTerm: query.search,
      sortProperty:
        Array.isArray(query.orderByCollection) && query.orderByCollection.length === 1
          ? columns[query.orderByCollection[0].orderBy].field
          : view.defaultSortField,
      direction:
        Array.isArray(query.orderByCollection)
        && query.orderByCollection.length === 1
        && query.orderByCollection[0].orderDirection
          ? query.orderByCollection[0].orderDirection.toUpperCase()
          : DIRECTION_DESC,
    };
    axios
      .post(`${MAIN_HOST}${view.endPoint}`, request)
      .then((apiResponse) => {
        const responseData = apiResponse.data;
        result.data = responseData.content;
        result.page = responseData.number;
        result.size = responseData.size;
        result.totalCount = responseData.totalElements;
      })
      .catch(() => store.dispatch(decreaseFetchingQueue()))
      .finally(() => resolve(result));
  });
}

export function getFileContent(columns, query, data) {
  return new Promise((resolve) => {
    const request = {
      filePath: data.filePath,
      pageSize: query.pageSize,
      lastRowNum: query.page * query.pageSize,
      searchTerm: query.search,
    };
    const result = {
      data: [],
      page: query.page,
      totalCount: 0,
    };

    axios
      .post(`${MAIN_HOST}/claims/file-content/claim-summary-file`, request)
      .then((apiResponse) => {
        const responseData = apiResponse.data;
        result.data = responseData.content;
        result.page = query.page;
        result.totalCount = responseData.total;
      })
      .catch(() => store.dispatch(decreaseFetchingQueue()))
      .finally(() => resolve(result));
  });
}
