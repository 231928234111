import React from 'react';
import {
  CONVERT_OBJECT_ARRAY_COMMA_STRING,
  FORMAT_RATE,
  FORMAT_RATIO,
} from 'common/util/commonUtil';
import {
  DIRECTION_DESC,
  OUTLIER_INDICATOR_DISPLAY,
  PANORAMA_VERSION_ONE,
  PRIMARY_GRAY,
  PROVIDER_AND_NPI_KEYS,
  SORT_KEY_MAPPING,
} from 'common/Constants';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box } from '@mui/system';
import { makeStyles } from '@mui/styles';
import { Tooltip, Typography } from '@mui/material';
import { getMrfRatesResult } from './state/service';

export const formKeywordString = (getValues, searchFilters, btnSource) => {
  let keyword = '';
  if (btnSource === 'Export') {
    getValues('selectedCodes').concat(getValues('selectedProviders')).forEach((selectedObj) => {
      keyword += `${selectedObj.value.trim()},`;
    });
  } else {
    searchFilters.current.selectedCodes.concat(searchFilters.current.selectedProviders)
      .forEach((selectedObj) => {
        keyword += `${selectedObj.value.trim()},`;
      });
  }
  keyword = keyword.substring(0, keyword.length - 1);
  return keyword;
};

export const generateRequestObjectforV1 = (
  query,
  columnOrderByField,
  stateMsaOption,
  searchFilters,
  getValues,
) => {
  const request = {
    page: query.page,
    size: query.pageSize,
    searchTerm: query.search,
    sortProperty: columnOrderByField,
    direction: Array.isArray(query.orderByCollection)
      && query.orderByCollection.length === 1
      && query.orderByCollection[0].orderDirection
      ? query.orderByCollection[0].orderDirection.toUpperCase()
      : DIRECTION_DESC,
    keyword: formKeywordString(getValues, searchFilters) || null,
    state: (stateMsaOption === 'state' && searchFilters.current.state?.code) || null,
    msaNo: (stateMsaOption === 'msa' && searchFilters.current.msa?.msaNo) || null,
    network: CONVERT_OBJECT_ARRAY_COMMA_STRING(
      searchFilters.current.planGroupAlias,
      'networkCode',
    ),
    planType: searchFilters.current.planType,
    billingClass: searchFilters.current.billingClass,
    negotiationArrangement: searchFilters.current.negotiationArrangement,
    negotiatedType: searchFilters.current.negotiatedType,
    mrfMinRate: searchFilters.current.mrfMinRate
      || null, // java expects Double value
    mrfMaxRate: searchFilters.current.mrfMaxRate || null,
    outlier: searchFilters.current.outlier?.value || null,
    providerEntityType: searchFilters.current.providerEntityType?.value,
  };
  return request;
};

const getProviderAndCode = (searchFilters) => {
  const resultData = {
    selectedCodes: { },
    selectedProviders: { },
  };
  ['selectedCodes', 'selectedProviders'].map((key) =>
    searchFilters.current[key].map((data) => {
      const dataType = PROVIDER_AND_NPI_KEYS[data.type] || data.type;
      if (!resultData[key][dataType]) {
        resultData[key][dataType] = [];
      }
      let dataValue;
      if (key === 'selectedCodes' || dataType === 'NPI' || dataType === 'TAX-ID') {
        dataValue = data.searchValue;
      } else {
        dataValue = data.value;
      }
      return resultData[key][dataType].push(dataValue);
    }));
  return resultData;
};

export const generateRequestObjectforV2 = (
  query,
  columnOrderByField,
  stateMsaOption,
  searchFilters,
) => {
  const statesArray = stateMsaOption === 'state'
    ? [searchFilters.current.state?.code]
    : searchFilters.current?.msa?.stateGroup?.split(',');
  const selectedCodesAndProvider = getProviderAndCode(searchFilters);
  const networkString = CONVERT_OBJECT_ARRAY_COMMA_STRING(
    searchFilters.current.planGroupAlias,
    'networkCode',
  );
  const networkArray = searchFilters.current.planGroupAlias.length > 0 ? networkString.split(',') : [];
  const outlierArray = !['', null].includes(searchFilters.current.outlier) ? searchFilters.current.outlier.value.split(',').map((num) => Number(num.trim())) : [];

  const request = {
    states: statesArray, // If msa is select pass the mapped states
    provider: selectedCodesAndProvider.selectedProviders,
    codeCategory: selectedCodesAndProvider.selectedCodes,
    network: networkArray,
    mrfMinRate: searchFilters.current.mrfMinRate || null,
    mrfMaxRate: searchFilters.current.mrfMaxRate || null,
    planType: searchFilters.current.planType,
    billingClass: searchFilters.current.billingClass?.toLowerCase(),
    npiType: searchFilters.current.providerEntityType?.value,
    negotiatedType: searchFilters.current.negotiatedType,
    negotiationArrangement: searchFilters.current.negotiationArrangement,
    outlier: outlierArray,
    pageSize: query.pageSize || 20,
    pageOffset: query.page,
    sortProperty: SORT_KEY_MAPPING[columnOrderByField],
    sortDirection: Array.isArray(query.orderByCollection)
      && query.orderByCollection.length === 1
      && query.orderByCollection[0].orderDirection
      ? query.orderByCollection[0].orderDirection.toUpperCase()
      : DIRECTION_DESC,
  };
  return request;
};

export const getMrfData = async (
  query,
  columnOrderByField,
  stateMsaOption,
  searchFilters,
  panoramaVersion,
  setExportSortProperty,
  setExportSortDirection,
  setCount,
  setCountVisibility,
  resolve,
  getValues,
  store,
) => {
  let request = {};
  if (panoramaVersion === PANORAMA_VERSION_ONE) {
    request = generateRequestObjectforV1(
      query,
      columnOrderByField,
      stateMsaOption,
      searchFilters,
      getValues,
    );
  } else {
    request = generateRequestObjectforV2(
      query,
      columnOrderByField,
      stateMsaOption,
      searchFilters,
      getValues,
    );
  }

  // set sort property and direction, need to consider when user export
  // with out hitting search
  setExportSortProperty(request.sortProperty);
  setExportSortDirection(request.direction);

  await getMrfRatesResult(
    request,
    setCount,
    setCountVisibility,
    resolve,
    panoramaVersion,
    store,
  );
};

export const useStyles = makeStyles(() => ({
  filterInput: {
    backgroundColor: '#ffffff',
  },
  blurText: {
    color: 'transparent',
    textShadow: '0 0 8px #000',
  },
  codeTooltip: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: PRIMARY_GRAY,
    marginLeft: '1rem',
    paddingLeft: '2px',
    paddingRight: '2px',
    borderRadius: '5px',
  },
  tooltipIconStyle: {
    fontSize: '0.8rem',
  },
  tooltipTextStyle: {
    fontSize: '12px',
  },
}));

export const getColumns = (networkMap, styles) => [
  {
    title: 'Code',
    field: 'billingcode',
    width: '8%',
    sorting: true,
    render: (rowData) => (
      <Box style={{ display: 'flex', alignItems: 'center' }}>
        <Tooltip title={rowData?.billingcodedescription} placement="top-start">
          <Box>
            {rowData.billingcodetype}
            {' '}
            {rowData.billingcode}
          </Box>
        </Tooltip>
        {rowData?.processinginformation === 'APR-DRG' && (
          <Tooltip title="This MS-DRG was translated from an APR-DRG" placement="bottom-start" tabIndex={0}>
            <Box className={styles.codeTooltip}>
              <InfoOutlinedIcon className={styles.tooltipIconStyle} />
              <Typography className={styles.tooltipTextStyle}>Translated</Typography>
            </Box>
          </Tooltip>
        )}
      </Box>
    ),
  },
  {
    title: (
      <Tooltip title="Indicates whether the charge is for a professional or facility item or service" placement="top">
        <span>Billing Class</span>
      </Tooltip>
    ),
    field: 'billingclass',
    sorting: true,
    width: '5%',
  },
  { title: 'Plan Type', field: 'plantype', sorting: true, width: '5%' },
  {
    title: 'Network',
    field: 'plangroupalias',
    width: '10%',
    sorting: false,
    render: (rd) =>
      networkMap[rd.plangroupalias] || rd.plangroupalias,
  },
  { title: 'Provider Name', field: 'providername', sorting: false, width: '25%' },
  {
    title: 'MRF Rate',
    field: 'rate',
    width: '5%',
    sorting: true,
    alignRight: true,
    // eslint-disable-next-line no-confusing-arrow
    render: (rd) => FORMAT_RATE(rd.rate),
    // rd.npi ? FORMAT_RATE(rd.max) : FORMAT_RATE(rd.percentile50),
  },
  {
    title: 'NPI Taxonomy',
    field: 'nucc',
    sorting: false,
    width: '20%',
  },
  {
    title: 'NPI Type',
    field: 'providerentitytype',
    sorting: false,
    width: '5%',
    align: 'center',
  },
  {
    title: (
      <Tooltip title="Indicates the type of MRF rate" placement="top">
        <span>Negotiated Type</span>
      </Tooltip>
    ),
    field: 'negotiatedtype',
    width: '5%',
    sorting: true,
  },
  {
    title: 'Negotiation Arrangement',
    field: 'negotiationarrangement',
    sorting: true,
    width: '5%',
  },
  {
    title: (
      <Tooltip title="For providers located within an MSA, comparison to the Medicare rate" placement="top">
        <span>Medicare Ratio</span>
      </Tooltip>
    ),
    field: 'institutionmedicareratio',
    sorting: false,
    width: '5%',
    alignRight: true,
    // eslint-disable-next-line no-confusing-arrow
    render: (rd) =>
      rd.institutionmedicareratio && rd.institutionmedicare && rd.institutionmedicareratio !== 0 && rd.institutionmedicareratio !== '0'
        ? FORMAT_RATIO(rd.institutionmedicareratio, rd.institutionmedicare) : ''
    ,
  },
  {
    title: (
      <Tooltip title="For providers located within an MSA, comparison to the industry benchmark rate" placement="top">
        <span>Benchmark Ratio</span>
      </Tooltip>
    ),
    field: 'medianrateratio',
    sorting: false,
    width: '5%',
    alignRight: true,
    // eslint-disable-next-line no-confusing-arrow
    render: (rd) =>
      rd.medianrateratio && rd.medianrate && rd.medianrateratio !== 0 && rd.medianrateratio !== '0'
        ? FORMAT_RATIO(rd.medianrateratio, rd.medianrate) : '',

  },
  {
    title: 'Outlier',
    field: 'outlierindicator',
    width: '5%',
    sorting: false,
    render: (rd) => OUTLIER_INDICATOR_DISPLAY[rd.outlierindicator],
  },
];
