/* eslint-disable no-param-reassign */
import CustomizedMaterialTable from 'common/components/CustomizedMaterialTable';
import {
  Button,
  Grid,
  Stack,
  TextField,
  Autocomplete,
  Box,
  ButtonGroup,
  Tooltip,
  Typography,
  Paper,
} from '@mui/material';
import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import { useSelector } from 'react-redux';
import { grey } from '@mui/material/colors';
import { CONVERT_OBJECT_ARRAY_COMMA_STRING } from 'common/util/commonUtil';
import { updateSystemErrorMsg } from 'app/state/reducer';
import store from 'app/store';
import { getAllMsa, getAllNetworks, getAllPlanType } from 'features/common/state/service';
import * as Constants from 'common/Constants';
import { PERMISSION } from 'common/Constants';
import { usePermissions } from 'features/common/hooks/usePermissions';
import emptyStateLogo from 'asset/empty-state-illusrtation-state-msa.png';
import TagButton from 'common/components/TagButton';
import FormAutocompleteTextField from 'common/components/FormAutocompleteTextField';
import { searchNetworks } from './state/service';
import DetailDialog from './component/detailDialog';
import DownloadConfirmDialog from './component/downloadConfirmDialog';
import CustomInfoIcon from '../../common/components/CustomInfoIcon';
import UtilityButtons from './component/UtilityButtons';
import AdvanceControls from './component/AdvanceControls';
import SelectCodesOrProviders from './component/SelectCodesOrProviders';
import {
  defaultSearchParams,
  clearPrevVisitState,
  setPrevVisitState,
  setStateMsaOption,
  setAdvanceControlsVisible,
} from './state/reducer';
import { getColumns, formKeywordString, getMrfData, useStyles } from './util';

export default function Search() {
  const networkData = useSelector((state) => state.search.networks);
  const networkMap = useSelector((state) => state.provider.networkMap);
  const msaOptions = useSelector((state) => state.provider.msaOptions);
  const prevVisitState = useSelector((state) => state.search.prevVisitState);
  const { hasPermission } = usePermissions();

  const [networkOptions, setNetworkOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const stateMsaOption = useSelector((state) => state.search.stateMsaOption);
  const [searchParamData, setSearchParamData] = useState({});
  const [downloadDialogOpen, setDownloadDialogOpen] = useState(false);
  const [disableSearch, setDisableSearch] = useState(true);
  const advanceControlsVisible = useSelector((state) => state.search.advanceControlsVisible);
  const methods = useForm({ defaultValues: { ...defaultSearchParams } });
  const { handleSubmit, control, getValues, setValue, watch, reset } = methods;
  const styles = useStyles();
  const tableRef = useRef();
  const codeRef = useRef(''); // to store the state codes('AZ,CA,...') for filtering networks
  const watchMsa = watch('msa');
  const watchState = watch('state');
  const watchCodes = watch('selectedCodes');
  const watchProviders = watch('selectedProviders');
  const [count, setCount] = useState(0);
  const [countVisibility, setCountVisibility] = useState(false);
  const [searchBtnFirstClick, setSearchBtnFirstClick] = useState(false);
  const [exportSortProperty, setExportSortProperty] = useState('');
  const [exportSortDirection, setExportSortDirection] = useState('');
  const clearCount = useRef(0);
  const removeChoosenNetworks = useRef(false); // don't remove on mount
  const searchFilters = useRef({ ...prevVisitState.form });
  const panoramaVersion = useSelector((state) => state.app.panoramaVersion);
  const columns = getColumns(networkMap, styles);
  const parseNetworks = () => {
    const formattedState = networkData.map((nw) => ({ label: nw.label, code: nw.code }));
    setStateOptions(formattedState);
  };

  const handleStateMsaOptionChange = (value) => {
    setValue('planGroupAlias', []);
    store.dispatch(setStateMsaOption(value));
  };

  const updateNetworkOptions = () => {
    const codeArray = codeRef.current.split(',');
    const filteredData = networkData.filter((nd) => codeArray.includes(nd.code));

    const uniqueNetworks = [];
    const selectedPlanType = getValues('planType');
    filteredData.forEach((state) => {
      state.networks.forEach((networkObj) => {
        // eliminate duplicates and only include networks having selected plan type
        let include = false;
        const duplicate = uniqueNetworks.some(
          (network) => network.networkCode === networkObj.networkCode,
        );
        if (!duplicate && (!selectedPlanType || selectedPlanType === 'ALL' || networkObj.planType === selectedPlanType)) include = true;

        if (include) uniqueNetworks.push({ ...networkObj });
      });
    });

    uniqueNetworks.sort((net1, net2) => net1?.label.localeCompare(net2?.label));

    setNetworkOptions(uniqueNetworks);
  };

  const changeNetworkOptions = (code) => {
    if (code) codeRef.current = code;
    if (removeChoosenNetworks.current) setValue('planGroupAlias', []);
    removeChoosenNetworks.current = true;
    updateNetworkOptions();
  };

  const handleClearSearch = () => {
    clearCount.current += 1;
    reset();
    if (stateMsaOption === 'state') setNetworkOptions([]);
    else store.dispatch(setStateMsaOption('state'));
    store.dispatch(clearPrevVisitState());
    setSearchBtnFirstClick(false);
    setCountVisibility(false);
  };

  const handleSubmitDownload = () => {
    const data = {
      keyword: formKeywordString(getValues, searchFilters, 'Export') || null,
      state: (stateMsaOption === 'state' && getValues('state')?.code) || null,
      msaNo: (stateMsaOption === 'msa' && getValues('msa')?.msaNo) || null,
      msaName: (stateMsaOption === 'msa' && getValues('msa')?.msaName) || null,
      network: CONVERT_OBJECT_ARRAY_COMMA_STRING(
        getValues('planGroupAlias'),
        'networkCode',
      ),
      networkLabel: CONVERT_OBJECT_ARRAY_COMMA_STRING(
        getValues('planGroupAlias'),
        'label',
      ),
      planType: getValues('planType') === 'ALL' ? '' : getValues('planType'),
      billingClass: getValues('billingClass') === 'ALL' ? '' : getValues('billingClass').toLowerCase(),
      negotiatedType: getValues('negotiatedType') === 'ALL' ? '' : getValues('negotiatedType'),
      mrfMinRate: getValues('mrfMinRate'),
      mrfMaxRate: getValues('mrfMaxRate'),
      outlier: getValues('outlier')?.value || null,
      sortProperty: exportSortProperty,
      sortDirection: exportSortDirection,
      providerEntityType: getValues('providerEntityType')?.value,
      negotiationArrangement: getValues('negotiationArrangement'),
    };
    if (data.keyword.length > Constants.MAX_KEYWORD_LENGTH) {
      store.dispatch(updateSystemErrorMsg(Constants.LONG_KEYWORD_ERR_MSG));
    } else {
      setDownloadDialogOpen(true);
      setSearchParamData(data);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    store.dispatch(searchNetworks());
    if (panoramaVersion === Constants.STUDY_VERSION_ONE) {
      store.dispatch(getAllNetworks());
    }
    store.dispatch(getAllMsa());
    store.dispatch(getAllPlanType());
    reset({ ...prevVisitState.form }, { keepDefaultValues: true });
    if (prevVisitState.tableQuery) setSearchBtnFirstClick(true);
  }, []);

  useLayoutEffect(() => () => {
    const queryState = tableRef?.current?.state.query;
    let tableQuery = null;
    if (queryState) {
      const orderByArray = queryState.orderByCollection;
      tableQuery = { // pick only serializable and required props
        page: queryState.page,
        pageSize: queryState.pageSize,
        orderByCollection: orderByArray?.length === 1 ? [{ ...orderByArray[0] }] : null,
      };
    }
    store.dispatch(setPrevVisitState({ form: getValues(), tableQuery }));
  }, []);

  useEffect(() => {
    const shouldQuery = searchBtnFirstClick && prevVisitState.tableQuery;
    if (shouldQuery) tableRef.current.onQueryChange(prevVisitState.tableQuery);
  }, [searchBtnFirstClick]);

  useEffect(() => {
    parseNetworks();
  }, [networkData]);

  useEffect(() => {
    // enable search and export only if state/msa and (code(s) and/or provider(s)) are selected
    if (((stateMsaOption === 'state' && watchState) || (stateMsaOption === 'msa' && watchMsa)) && (watchCodes.length || watchProviders.length)) {
      setDisableSearch(false);
    } else setDisableSearch(true);
  }, [watchMsa, watchState, stateMsaOption, watchCodes, watchProviders]);

  useEffect(() => {
    setNetworkOptions([]);
    const valueObj = getValues(stateMsaOption);
    if (valueObj) changeNetworkOptions(stateMsaOption === 'state' ? valueObj.code : valueObj.stateGroup);
  }, [stateMsaOption]);

  useEffect(() => {
    if (!advanceControlsVisible) {
      reset({
        ...getValues(),
        planType: '',
        billingClass: '',
        negotiatedType: '',
        mrfMinRate: '',
        mrfMaxRate: '',
        outlier: '',
        negotiationArrangement: '',
      }, { keepDefaultValues: true });
    }
  }, [advanceControlsVisible]);

  const prevVisitSorted = Array.isArray(prevVisitState.tableQuery?.orderByCollection);
  if (prevVisitSorted) {
    const orderByField = columns[prevVisitState.tableQuery.orderByCollection[0].orderBy]?.field;
    if (orderByField) {
      columns.forEach((column) => {
        if (column.field === orderByField) {
          column.defaultSort = prevVisitState.tableQuery.orderByCollection[0].orderDirection;
        }
      });
    }
  }

  return (
    <FormProvider {...methods}>
      <Grid container>
        <Grid item xs={12}>
          <Stack spacing={2}>
            <Paper sx={{ p: 1.5, mb: 1.5 }}>
              <Grid container direction="row" justifyContent="space-between" spacing={1.5} columnSpacing={{ md: 1 }} mb={1.5}>
                <Grid item container xs={11} xl spacing={3}>
                  {/* Main controls */}
                  <Grid item container direction="row" rowSpacing={1.5} columnSpacing={2}>
                    <Grid item xs={12} md={6} xl={3.5}>
                      <Stack spacing={1.5}>
                        <ButtonGroup variant="text">
                          <TagButton
                            key="state"
                            label="State"
                            color="tag"
                            variant={stateMsaOption === 'state' ? 'contained' : 'text'}
                            onClick={() => handleStateMsaOptionChange('state')}
                          />
                          <TagButton
                            key="msa"
                            label="MSA"
                            color="tag"
                            variant={stateMsaOption === 'msa' ? 'contained' : 'text'}
                            onClick={() => handleStateMsaOptionChange('msa')}
                          />
                          <CustomInfoIcon title="Toggle between searching by State or MSA" />
                        </ButtonGroup>
                        <Grid container columns={5} spacing={1.5} justifyContent="space-between" pr="12px">
                          <Grid item xs={2} p="0 !important">
                            {stateMsaOption === 'state'
                              && (
                                <Controller
                                  name="state"
                                  control={control}
                                  render={({ field }) => (
                                    <Autocomplete
                                      id="stateFilter"
                                      {...field}
                                      onChange={(_e, option) => {
                                        changeNetworkOptions(option.code);
                                        field.onChange(option);
                                      }}
                                      fullWidth
                                      disableClearable
                                      options={stateOptions}
                                      renderInput={(inProps) => (
                                        <TextField
                                          className={styles.filterInput}
                                          {...inProps}
                                          label="State"
                                          required
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                        />
                                      )}
                                    />
                                  )}
                                />
                              )}
                            {stateMsaOption === 'msa'
                              && (
                                <Controller
                                  name="msa"
                                  control={control}
                                  render={({ field }) => (
                                    <Autocomplete
                                      id="msaFilter"
                                      {...field}
                                      onChange={(_e, option) => {
                                        changeNetworkOptions(option.stateGroup);
                                        field.onChange(option);
                                      }}
                                      fullWidth
                                      disableClearable
                                      options={msaOptions.filter((item) => item?.msaName && item?.msaName !== '')}
                                      getOptionLabel={(option) => {
                                        if ('msaName' in option) {
                                          if (typeof option.msaName === 'string' && option.msaName.endsWith(' MSA')) {
                                            return option.msaName.slice(0, -4);
                                          }
                                          return option.msaName;
                                        }
                                        return null;
                                      }}
                                      renderInput={(inProps) => (
                                        <TextField
                                          className={styles.filterInput}
                                          {...inProps}
                                          label="MSA"
                                          required
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                        />
                                      )}
                                    />
                                  )}
                                />
                              )}
                          </Grid>
                          <Grid item xs={3} pt="0 !important">
                            <Controller
                              name="planGroupAlias"
                              control={control}
                              render={({ field }) => (
                                <Tooltip title="Select one or more Networks (optional)" placement="top">
                                  <Autocomplete
                                    id="payerNetworkFilter"
                                    {...field}
                                    fullWidth
                                    multiple
                                    onChange={(_, data) => field.onChange(data)}
                                    options={networkOptions}
                                    filterSelectedOptions
                                    getOptionLabel={(option) => option.label}
                                    isOptionEqualToValue={(option, value) =>
                                      option.networkCode === value.networkCode
                                    }
                                    renderInput={(inProps) => (
                                      <TextField
                                        {...inProps}
                                        className={styles.filterInput}
                                        label="Network"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                      />
                                    )}
                                  />
                                </Tooltip>
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Stack>
                    </Grid>
                    {['CODE', 'PROVIDER'].map((variant) => (
                      <Grid item xs={12} md={6} xl={3.25} key={variant}>
                        <SelectCodesOrProviders
                          variant={variant}
                          stateMsaOption={stateMsaOption}
                          clearCount={clearCount.current}
                          selectedStateObj={watchState}
                          selectedMsaObj={watchMsa}
                        />
                      </Grid>
                    ))}
                    <Grid item xs={6} md={3} xl={2}>
                      <Stack spacing={1.5}>
                        {/* Add ghost element to align the field well with others in the row */}
                        <Box sx={{ visibility: 'hidden', display: { xs: 'none', md: 'inline-flex' } }}>
                          <CustomInfoIcon />
                        </Box>
                        <FormAutocompleteTextField
                          fieldName="providerEntityType"
                          autocompleteProps={{
                            id: 'npiTypeDropdown',
                            options: Constants.NPI_TYPES,
                            isOptionEqualToValue: (option, value) => option.value === value.value,
                          }}
                          textFieldProps={{
                            label: 'NPI Type',
                          }}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                  { /* second line of controls */
                    advanceControlsVisible
                    && <AdvanceControls onPlanTypeChange={changeNetworkOptions} />
                  }
                </Grid>
                {/* search button */}
                <Grid item xs="auto" alignSelf="flex-end">
                  <Tooltip
                    title={
                      disableSearch ? 'Must select State/MSA and 1 or more keywords' : 'Click here to start Search'
                    }
                  >
                    <span>
                      <Button
                        autoFocus
                        disabled={disableSearch}
                        variant="contained"
                        onClick={handleSubmit(() => {
                          searchFilters.current = getValues();
                          if (!tableRef.current) setSearchBtnFirstClick(true);
                          else tableRef.current.onQueryChange({ page: 0 });
                        })}
                        color="primary">
                        Search
                      </Button>
                    </span>
                  </Tooltip>
                </Grid>
              </Grid>
              <UtilityButtons
                advanceControlsVisible={advanceControlsVisible}
                toggleAdvanceControls={
                  () => store.dispatch(setAdvanceControlsVisible(!advanceControlsVisible))
                }
                clearSearch={handleClearSearch}
                exportSearch={() => handleSubmitDownload()}
                searchDisabled={disableSearch}
              />
            </Paper>
            <Grid item style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', marginTop: '0px', fontSize: '0.875rem' }}>
              {countVisibility
                && (
                  <Typography variant="inherit">
                    {
                      `Displaying ${count.toLocaleString('en-US')} rate records`
                    }
                  </Typography>
                )}
            </Grid>
            <Grid item pt={3} style={{ paddingTop: '5px', marginTop: '0px' }}>
              {searchBtnFirstClick === true && (
                <CustomizedMaterialTable
                  options={{
                    pageSize: prevVisitState.tableQuery ? prevVisitState.tableQuery.pageSize : 20,
                    debounceInterval: 400,
                    actionsColumnIndex: -1,
                    rowStyle: {
                      backgroundColor: 'white',
                      color: '#212121',
                      fontSize: '0.875rem',
                    },
                    toolbar: false,
                    padding: 'dense',
                    search: false,
                    headerStyle: {
                      backgroundColor: '#C7D0DB',
                      color: '#212121',
                    },
                  }}
                  tableRef={tableRef}
                  columns={columns}
                  data={(query) =>
                    new Promise((resolve) => {
                      if (disableSearch === false) {
                        const columnOrderByField = Array.isArray(query.orderByCollection)
                          && query.orderByCollection.length === 1
                          ? columns[query.orderByCollection[0].orderBy].field
                          : '';
                        getMrfData(
                          query,
                          columnOrderByField,
                          stateMsaOption,
                          searchFilters,
                          panoramaVersion,
                          setExportSortProperty,
                          setExportSortDirection,
                          setCount,
                          setCountVisibility,
                          resolve,
                          getValues,
                          store,
                        );
                      } else {
                        resolve({
                          data: [],
                          page: 0,
                          totalCount: 0,
                        });
                      }
                    })
                  }
                  detailPanel={(rowData) => (
                    <Box
                      sx={{
                        background: grey[100],
                      }}
                      padding={0.005}>
                      <DetailDialog data={rowData} />
                    </Box>
                  )}
                />
              )}
              <Grid item container justifyContent="center" paddingBottom="2.5%" style={{ marginTop: '20px' }}>
                {searchBtnFirstClick === false && (
                  <img src={emptyStateLogo} alt="Select state or MSA first" />
                )}
              </Grid>
            </Grid>
          </Stack>
        </Grid>
      </Grid>
      {hasPermission(PERMISSION.DOWNLOAD_SEARCH) && (
        <DownloadConfirmDialog
          data={searchParamData}
          open={downloadDialogOpen}
          onClose={() => {
            setDownloadDialogOpen(false);
          }}
        />
      )}
    </FormProvider>
  );
}
