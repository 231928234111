import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Add, DeleteForever, List } from '@mui/icons-material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { FORMAT_NATURAL, convertTimestamp } from 'common/util/commonUtil';
import CustomizedMaterialTable from 'common/components/CustomizedMaterialTable';
import { TABULAR_VIEWS, PRIMARY } from 'common/Constants';
import getDataForTabularViews from 'common/service';
import StudiesListDialog from 'features/evaluation/component/studiesListDialog';
import ClaimsDetailDialog from './ClaimsDetailDialog';

export default function ClaimsFiles() {
  const tableRef = useRef();
  const userId = useSelector((state) => state.auth.userId);
  const [studiesListId, setStudiesListId] = useState(null);
  const [setIsUploadDialogOpen] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [data, setData] = useState(undefined);

  const refresh = () => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  };

  const handleRowClick = (rowData) => {
    setData(rowData);
    setDialogOpen(true);
  };

  const handleListClaimsStudies = (id) => {
    setStudiesListId(id);
  };

  const columns = [
    {
      title: 'Name',
      field: 'name',
      width: '30%',
      cellStyle: {
        width: '30%',
        overflowWrap: 'anywhere',
      },
      render: (rowData) => (
        <button
          type="button"
          style={{
            backgroundColor: 'transparent',
            border: 'none',
            outlineColor: PRIMARY,
            cursor: 'pointer',
            display: 'inline',
            margin: 0,
            padding: 0,
            fontSize: '1rem',
            overflowWrap: 'anywhere',
            textAlign: 'left',
            whiteSpace: 'normal',
            color: PRIMARY,
          }}
          onClick={() => handleRowClick(rowData)}
        >
          {rowData.name}
        </button>
      ),
    },
    {
      title: 'Updated At',
      field: 'updatedAt',
      width: '17%',
      cellStyle: { width: '17%' },
      render: (rowData) => convertTimestamp(rowData.updatedAt),
    },
    {
      title: 'Procedure Code Count',
      field: 'procedureCodeCount',
      width: '8%',
      cellStyle: { width: '8%' },
      render: (rowData) => FORMAT_NATURAL(rowData.procedureCodeCount),
    },
    {
      title: 'NPI Count',
      field: 'npiCount',
      width: '8%',
      cellStyle: { width: '8%' },
      render: (rowData) => FORMAT_NATURAL(rowData.npiCount),
    },
    {
      title: 'Total Spend',
      field: 'totalSpend',
      width: '8%',
      cellStyle: { width: '8%' },
      render: (rowData) => FORMAT_NATURAL(rowData.totalSpend),
    },
    {
      title: 'Studies',
      field: 'studiesCount',
      width: '8%',
      cellStyle: { width: '8%' },
      render: (rowData) => FORMAT_NATURAL(rowData.studiesCount),
    },
    {
      title: 'Uploaded By',
      field: 'uploadedBy',
      width: '20%',
      cellStyle: {
        width: '20%',
      },
    },
  ];

  return (
    <>
      <ClaimsDetailDialog data={data} handleClose={() => setDialogOpen(false)} open={dialogOpen} />

      <CustomizedMaterialTable
        title={TABULAR_VIEWS().claims.tableTitle}
        tableRef={tableRef}
        options={{
          pageSize: 10,
          actionsColumnIndex: -1,
        }}
        localization={{
          toolbar: { searchPlaceholder: 'Filter', searchTooltip: 'Filter' },
        }}
        columns={columns}
        data={(query) => getDataForTabularViews('claims', columns, query)}
        actions={[
          (rowInfo) => ({
            icon: () => <List />,
            tooltip: 'List Studies',
            disabled: !rowInfo.studiesCount,
            onClick: (_, rowData) => {
              handleListClaimsStudies(rowData?.id);
            },
          }),
          (rowInfo) => ({
            icon: () => <DeleteForever id="delete_claims_summary_file" />,
            tooltip: 'Delete',
            disabled: !!rowInfo.noOfStudies || rowInfo.createdBy !== userId,
            onClick: () => {
            },
          }),
          {
            icon: () => <Add />,
            tooltip: 'Add',
            isFreeAction: true,
            onClick: () => setIsUploadDialogOpen(true),
          },
          {
            icon: () => <RefreshIcon />,
            tooltip: 'Refresh Data',
            isFreeAction: true,
            onClick: refresh,
          },
        ]}
      />
      {studiesListId && (
        <StudiesListDialog
          id={studiesListId}
          handleClose={() => {
            setStudiesListId(null);
          }}
          viewName="claims"
        />
      )}
    </>
  );
}
